import React, { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';

const HeaderButton = () => {
  const gContext = useContext(GlobalContext);

  return (
    <button
      onClick={() =>
        (window.location.href = 'https://app.ustrive.com/students/student')
      }
      className="btn btn btn-dodger-blue-2 header-btn rounded-5"
    >
      Find a Mentor
    </button>
  );
};

export default HeaderButton;
