import React, { useContext, useEffect } from 'react';
import GlobalContext, { themeConfigDefault } from '../../context/GlobalContext';
// import VisitorPopup from '../VisitorPopup';
// const windowGlobal = typeof window !== 'undefined' && window;
const PageWrapper = ({ children, themeConfig = null }) => {
  const gContext = useContext(GlobalContext);

  useEffect(() => {
    gContext.changeTheme({ ...themeConfigDefault, ...themeConfig });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* {windowGlobal && <VisitorPopup />} */}
      {children}
    </>
  );
};

export default PageWrapper;
