import React from 'react';
import PageWrapper from '../components/PageWrapper';
import HeaderButton from '../components/HeaderButton';

const Terms = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: <HeaderButton />,
          footerStyle: 'style3',
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11">Terms of Service</h2>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-8">
                    <p>
                      UStrive.com (“UStrive.com”, “we,” or “us”) and our Website
                      at www.UStrive.com (the “Site”), as well as all related
                      web sites, networks, embeddable widgets, downloadable
                      software, mobile applications (including tablet
                      applications), the virtual mentoring platform operated by
                      UStrive (a third party service provider at
                      www.UStrive.com) on our behalf, and other services
                      provided by us and on which a link to these Terms of
                      Service is displayed (collectively, together with the
                      Site, our “Service”). These Terms of Service are a legally
                      binding contract between you and UStrive.com regarding
                      your use of the Service.
                    </p>

                    <p>
                      PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY. BY
                      CLICKING “I ACCEPT” OR BY ACCESSING OR USING THE SERVICE,
                      YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE
                      TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS,
                      INCLUDING THE UStrive.com PRIVACY POLICY)(COLLECTIVELY,
                      THESE “TERMS”).
                    </p>

                    <p>
                      If you are not eligible, or do not agree to these Terms,
                      then please do not use the Service.
                    </p>

                    <p>
                      These Terms of Service provide that all disputes between
                      you and UStrive.com will be resolved by BINDING
                      ARBITRATION. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO
                      COURT TO ASSERT OR DEFEND YOUR RIGHTS UNDER THIS CONTRACT
                      (except for matters that may be taken to small claims
                      court). Your rights will be determined by a NEUTRAL
                      ARBITRATOR and NOT A JUDGE OR JURY and your claims cannot
                      be brought as a class action. Please review Section 19
                      below for the details regarding your agreement to
                      arbitrate any disputes with UStrive.com.
                    </p>

                    <ol>
                      <li>
                        <p>
                          <strong>Eligibility.</strong> You must be at least
                          thirteen (13) years of age to use the Service. By
                          agreeing to these Terms, you represent and warrant to
                          us: (a) that you are at least thirteen (13) years of
                          age; (b) that you have not previously been suspended
                          or removed from the Service; and (c) that your
                          registration and your use of the Service is in
                          compliance with any and all applicable laws and
                          regulations. If you are using the Service on behalf of
                          an entity, organization, or company, you represent and
                          warrant that you have the authority to bind such
                          organization to these Terms and you agree to be bound
                          by these Terms on behalf of such organization.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Accounts and Registration.</strong> Certain
                          features of the Service do not require an account.
                          However, you may be required to register for an
                          account to use other features of the Service,
                          including for example: to use the virtual mentoring
                          platform operated by UStrive on our behalf; providing
                          and/or receiving mentorship; uploading, downloading,
                          accessing, and using content; and communicating with a
                          mentor or mentee. When you register for an account,
                          you may be required to provide us with some
                          information about yourself (such as your name, age,
                          phone number, e-mail address, name of your high
                          school, standardized test scores, GPA, income, race or
                          other contact information). You agree that the
                          information you provide to us is accurate and that you
                          will keep it accurate and up-to-date at all times.
                          When you register, you will be asked to provide a
                          password. You are solely responsible for maintaining
                          the confidentiality of your account and password. You
                          agree to accept responsibility for all activities that
                          occur under your account. UStrive.com may, now or in
                          the future, permit you to register for an account with
                          or log in to the Service through certain third party
                          social networking services, such as Facebook, Twitter
                          and/or Google (individually, a “Linked Account”). By
                          registering for or logging in to the Service with a
                          Linked Account, you agree that UStrive.com may access
                          and use any account information from the Linked
                          Account that you have configured to be made available
                          to third parties in this manner, and you agree to the
                          terms of service of the Linked Account regarding your
                          use of the Service via the Linked Account. If you have
                          reason to believe that your account is no longer
                          secure, then you must immediately notify us at
                          support@ustrive.com.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Donations.</strong> UStrive.com allows you to
                          make donations or contributions through the Service to
                          UStrive.com, its affiliate non-profit organizations,
                          or causes and programs advocated by UStrive.com and/or
                          its affiliate non-profit organizations. If you make
                          such a donation or contribution online, UStrive.com
                          will bill charges via PayPal using the account
                          information provided by you or as otherwise specified
                          by you, for example a credit card. You authorize
                          PayPal or such credit card account to pay any amounts
                          so donated or contributed by you, and authorize
                          UStrive.com (or its authorized payment processor) to
                          charge all sums described herein to your PayPal
                          account or such credit card account. You agree to
                          provide UStrive.com updated information regarding your
                          credit card account upon UStrive.com’s request and any
                          time the information earlier provided is no longer
                          valid.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>User Content</strong>
                        </p>

                        <ol>
                          <li>
                            <p>
                              <strong>User Content Generally.</strong> Certain
                              features of the Service may permit users to post
                              content, including messages, reviews, photos,
                              video, images, folders, data, text, and other
                              types of works (collectively, “User Content”) and
                              to publish User Content on the Service or
                              otherwise share User Content with other users. You
                              retain copyright and any other proprietary rights
                              that you may hold in the User Content that you
                              post to the Service.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>
                                Limited License Grant to UStrive.com.
                              </strong>{' '}
                              By posting or publishing User Content, you grant
                              UStrive.com a worldwide, non-exclusive,
                              royalty-free right and license (with the right to
                              sublicense) to host, store, transfer, display,
                              perform, reproduce, modify, and distribute your
                              User Content, in whole or in part, in any media
                              formats and through any media channels (now known
                              or hereafter developed). Any such use of your User
                              Content by UStrive.com may be without any
                              compensation paid to you.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>
                                Limited License Grant to Other Users.
                              </strong>{' '}
                              By posting and sharing User Content with another
                              user of the Service, you hereby grant that user a
                              non-exclusive license to access and use such User
                              Content as permitted by these Terms and the
                              functionality of the Service.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>
                                User Content Representations and Warranties.
                              </strong>
                              You are solely responsible for your User Content
                              and the consequences of posting or publishing User
                              Content. By posting and publishing User Content,
                              you affirm, represent, and warrant that:
                            </p>
                            <p>
                              You are the creator and owner of, or have the
                              necessary licenses, rights, consents, and
                              permissions to use and to authorize UStrive.com
                              and users of the Service to use and distribute
                              your User Content as necessary to exercise the
                              licenses granted by you in this Section 4 and in
                              the manner contemplated by UStrive.com and these
                              Terms; and Your User Content, and the use of User
                              Content as contemplated by the Terms, does not and
                              will not: (a) infringe, violate, or misappropriate
                              any third-party right, including any copyright,
                              trademark, patent, trade secret, moral right,
                              privacy right, right of publicity, or any other
                              intellectual property or proprietary right; or (b)
                              slander, defame, or libel any third-party.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>User Content Disclaimer.</strong> We are
                              under no obligation to edit or control User
                              Content that you or other users post or publish,
                              and will not be in any way responsible or liable
                              for User Content. UStrive.com may, however, at any
                              time and without prior notice, screen, remove,
                              edit, or block any User Content that in our sole
                              judgment violates these Terms or is otherwise
                              objectionable. You understand that when using the
                              Service you will be exposed to User Content from a
                              variety of sources and acknowledge that User
                              Content may be inaccurate, offensive, indecent or
                              objectionable. You agree to waive, and hereby do
                              waive, any legal or equitable rights or remedies
                              you have or may have against UStrive.com with
                              respect to User Content. We expressly disclaim any
                              and all liability in connection with User Content.
                              If notified by a user or content owner that User
                              Content allegedly does not conform to these Terms,
                              we may investigate the allegation and determine in
                              our sole discretion whether to remove the User
                              Content, which we reserve the right to do at any
                              time and without notice. For clarity, UStrive.com
                              does not permit copyright-infringing activities on
                              the Service.
                            </p>
                          </li>
                        </ol>
                        <li>
                          <p>
                            <strong>Digital Millennium Copyright Act</strong>
                          </p>

                          <ol>
                            <li>
                              <p>
                                <strong>DMCA Notification.</strong> We comply
                                with the provisions of the Digital Millennium
                                Copyright Act applicable to internet service
                                providers (17 U.S.C. §512, as amended). If you
                                have any complaints with respect to material
                                posted on the Service, you may contact our
                                Designated Agent at the following address:
                              </p>
                              <p>UStrive 360 Nueces St Austin, TX 78701</p>
                              <p>E-Mail: info@UStrive.com</p>
                              <p>
                                Any notice alleging that materials hosted by or
                                distributed through the Service infringe
                                intellectual property rights must include the
                                following information:
                              </p>
                              <ol>
                                <li>
                                  <p>
                                    an electronic or physical signature of the
                                    person authorized to act on behalf of the
                                    owner of the copyright or other right being
                                    infringed;
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    a description of the copyrighted work or
                                    other intellectual property that you claim
                                    has been infringed;
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    a description of the material that you claim
                                    is infringing and where it is located on the
                                    Service;
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    your address, telephone number, and email
                                    address;
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    a statement by you that you have a good
                                    faith belief that the use of the materials
                                    on the Service of which you are complaining
                                    is not authorized by the copyright owner,
                                    its agent, or the law; and
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    a statement by you that the above
                                    information in your notice is accurate and
                                    that, under penalty of perjury, you are the
                                    copyright or intellectual property owner or
                                    authorized to act on the copyright or
                                    intellectual property owner’s behalf.
                                  </p>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <p>
                                <strong>Repeat Infringers.</strong> UStrive.com
                                will promptly terminate without notice the
                                accounts of users that are determined by
                                UStrive.com to be “repeat infringers.” A repeat
                                infringer is a user who has been notified of
                                infringing activity more than twice and/or has
                                had User Content removed from the Service more
                                than twice.
                              </p>
                            </li>
                          </ol>
                          <li>
                            <p>
                              <strong>Prohibited Conduct.</strong>
                            </p>
                            <p>BY USING THE SERVICE YOU AGREE NOT TO:</p>
                            <ol>
                              <li>
                                <p>
                                  use the Service for any illegal purpose, or in
                                  violation of any local, state, national, or
                                  international law;
                                </p>
                              </li>
                              <li>
                                <p>
                                  violate, or encourage others to violate, the
                                  rights of third parties, including by
                                  infringing or misappropriating third party
                                  intellectual property rights;
                                </p>
                              </li>
                              <li>
                                <p>
                                  post, upload, or distribute any User Content
                                  or other content that is unlawful, defamatory,
                                  libelous, inaccurate, or that a reasonable
                                  person could deem to be objectionable,
                                  profane, indecent, pornographic, harassing,
                                  threatening, embarrassing, hateful, or
                                  otherwise inappropriate;
                                </p>
                              </li>
                              <li>
                                <p>
                                  interfere with security-related features of
                                  the Service, including without limitation by
                                  (a) disabling or circumventing features that
                                  prevent or limit use or copying of any
                                  content, or (b) reverse engineering or
                                  otherwise attempting to discover the source
                                  code of the Service or any part of the
                                  Service, except to the extent that activity is
                                  expressly permitted by applicable law;
                                </p>
                              </li>
                              <li>
                                <p>
                                  interfere with the operation of the Service or
                                  any user’s enjoyment of the Service, including
                                  without limitation by: (a) uploading or
                                  otherwise disseminating viruses, adware,
                                  spyware, worms, or other malicious code; (b)
                                  making unsolicited offers or advertisements to
                                  other users of the Service; (c) attempting to
                                  collect personal information about users or
                                  third parties without their consent; or (d)
                                  interfering with or disrupting any networks,
                                  equipment, or servers connected to or used to
                                  provide the Service, or violating the
                                  regulations, policies, or procedures of such
                                  networks, equipment, or servers;
                                </p>
                              </li>
                              <li>
                                <p>
                                  perform any fraudulent activity including
                                  impersonating any person or entity, claiming
                                  false affiliations, accessing the Service
                                  accounts of others without permission, or
                                  falsifying your age or date of birth;
                                </p>
                              </li>
                              <li>
                                <p>
                                  Sell or otherwise transfer the access granted
                                  in the Terms or any Materials (as defined in
                                  Section 13 below) or any right or ability to
                                  view, access, or use any Materials; or
                                </p>
                              </li>
                              <li>
                                <p>
                                  attempt to do any of the foregoing in this
                                  Section 6, or assist or permit any persons in
                                  engaging or attempting to engage in any of the
                                  activities described in this Section 6.
                                </p>
                              </li>
                            </ol>
                            <li>
                              <p>
                                <strong>Mentors.</strong> If you are a “mentor”
                                user of the Service or have applied to be a
                                “mentor” user, then you also agree to the
                                following additional terms.
                              </p>
                              <ol>
                                <li>
                                  <p>
                                    You will be subject to a background check
                                    when you apply to be a mentor user. You
                                    represent and warrant that any and all
                                    information you provide to us during the
                                    application process is true and accurate.
                                    You hereby grant us express consent to use
                                    such information to perform the background
                                    check in accordance with our Privacy Policy.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    After you have been granted the “mentor”
                                    status, you may be paired with certain other
                                    user(s) of the Service who have consented to
                                    receive advice from you and who you may
                                    consider as your “mentee”. As a mentor, you
                                    are still an independent user of the Service
                                    and are not deemed in any way personnel of
                                    UStrive.com. Nothing should be deemed to
                                    create any employment, contractor, agent or
                                    other affiliate relationship between you and
                                    UStrive.com. You are not allowed to make any
                                    representation or statement on behalf of, or
                                    in any manner that may be interpreted as
                                    being made on behalf of, UStrive.com. During
                                    the course of using the Service as a mentor,
                                    you may voluntarily provide advice or
                                    information to your mentees, in your own,
                                    individual capacity. Any such advice or
                                    information is provided on your own behalf,
                                    and does not represent any advice or view of
                                    UStrive.com.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    When interacting with your mentees through
                                    the Service, whether online or offline, you
                                    agree to comply with all guidelines and
                                    requirements provided by UStrive.com to you.
                                    You will not present any false, misleading
                                    or inappropriate information and will not
                                    engage in any illegal or inappropriate
                                    behaviors. You are not allowed to charge any
                                    fees to your mentees or ask for any monetary
                                    compensation for providing any information,
                                    advice or mentorship to your mentees.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    As a mentor, you may be provided with
                                    information (including personally
                                    identifiable information) about your mentees
                                    or other users of the Service, either by
                                    UStrive.com or directly by those
                                    individuals. You agree that any information
                                    provided to you is confidential in nature.
                                    You agree to protect and maintain the
                                    confidentiality of such information, to use
                                    such information only for the purposes of
                                    using the Service as a mentor, and not to
                                    disclose any such information to any third
                                    party. You will respect the privacy of the
                                    individuals with whom you interact and will
                                    not share with any third parties their
                                    information or stories without first
                                    obtaining express consent from UStrive.com
                                    and the individuals.
                                  </p>
                                </li>
                              </ol>
                              <li>
                                <p>
                                  <strong>Curriculum.</strong>Certain users of
                                  the Service (e.g., mentors and mentees) may
                                  have access to certain curriculum, course
                                  materials, and other content (collectively
                                  “Curriculum”) developed or licensed by
                                  UStrive.com. UStrive.com and its licensors
                                  retain all ownership rights including all
                                  associated intellectual property rights in and
                                  to the Curriculum. If you are provided with
                                  access to the Curriculum, you may use it only
                                  for the purpose of the Service and through our
                                  virtual mentoring platform. You will not
                                  download, store, copy, reproduce, modify,
                                  distribute or otherwise exploit the Curriculum
                                  unless you have first obtained express written
                                  consent from UStrive.com.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>
                                    Third-Party Services and Linked Websites.
                                  </strong>{' '}
                                  UStrive.com may provide tools through the
                                  Service that enable you to export information,
                                  including User Content, to third party
                                  services, including through features that
                                  allow you to link your account on UStrive.com
                                  with an account on the third party service,
                                  such as Twitter or Facebook, or through our
                                  implementation of third party buttons (such as
                                  “like” or “share” buttons). By using these
                                  tools, you agree that we may transfer such
                                  information to the applicable third-party
                                  service. Such third party services are not
                                  under our control, and we are not responsible
                                  for their use of your exported information.
                                  The Service may also contain links to
                                  third-party websites. Such linked websites are
                                  not under our control, and we are not
                                  responsible for their content.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>
                                    Termination of Use; Discontinuation and
                                    Modification of the Service.
                                  </strong>{' '}
                                  If you violate any provision of these Terms,
                                  your permission to use the Service will
                                  terminate automatically. Additionally,
                                  UStrive.com in its sole discretion may
                                  terminate your user account on the Service or
                                  suspend or terminate your access to the
                                  Service at any time, with or without notice.
                                  We also reserve the right to modify or
                                  discontinue the Service at any time
                                  (including, without limitation, by limiting or
                                  discontinuing certain features of the Service)
                                  without notice to you. We will have no
                                  liability whatsoever on account of any change
                                  to the Service or any suspension or
                                  termination of your access to or use of the
                                  Service. Upon any termination of your access
                                  to or use of the Service, any mentor-mentee
                                  relationship in connection with your prior use
                                  of the Service will terminate automatically.
                                  You may no longer represent yourself as a
                                  mentor or mentee with UStrive.com. You may
                                  terminate your account at any time by
                                  contacting customer service at
                                  info@UStrive.com.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>
                                    Privacy Policy; Additional Terms
                                  </strong>
                                </p>
                                <ol>
                                  <li>
                                    <p>
                                      <strong>Privacy Policy.</strong> Please
                                      read the UStrive.com Privacy Policy
                                      carefully for information relating to our
                                      collection, use, storage and disclosure of
                                      your personal information. The UStrive.com
                                      Privacy Policy is hereby incorporated by
                                      reference into, and made a part of, these
                                      Terms.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>Additional Terms.</strong> Your
                                      use of the Service is subject to any and
                                      all additional terms, policies, rules, or
                                      guidelines applicable to the Service or
                                      certain features of the Service that we
                                      may post on or link to on the Service (the
                                      “Additional Terms“), such as end-user
                                      license agreements for any downloadable
                                      applications that we may offer, or rules
                                      applicable to particular features or
                                      content on the Service, subject to Section
                                      12 below. All such Additional Terms are
                                      hereby incorporated by reference into, and
                                      made a part of, these Terms.
                                    </p>
                                  </li>
                                </ol>
                              </li>
                              <li>
                                <p>
                                  <strong>Changes to the Terms.</strong> We
                                  reserve the right, at our discretion, to
                                  change these Terms on a going-forward basis at
                                  any time. Please check these Terms
                                  periodically for changes. In the event that a
                                  change to these Terms materially modifies your
                                  rights or obligations, we will make reasonable
                                  efforts to notify you of such change. We may
                                  provide notice through a pop-up or banner
                                  within the Service, by sending an email to any
                                  address you may have used to register for an
                                  account, or through other mechanisms.
                                  Additionally, if the changed Terms materially
                                  modify your rights or obligations, we may
                                  require you to provide consent by accepting
                                  the changed Terms. If we require your
                                  acceptance of the changed Terms, changes are
                                  effective only after your acceptance. If you
                                  do not accept the changed Terms, we may
                                  terminate your access to and use of the
                                  Service. All other changes are effective upon
                                  publication of the changed Terms. Disputes
                                  arising under these Terms will be resolved in
                                  accordance with the Terms in effect at the
                                  time the dispute arose.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>
                                    Ownership; Proprietary Rights.
                                  </strong>{' '}
                                  The Service is owned and operated by
                                  UStrive.com. The visual interfaces, graphics,
                                  design, compilation, information, data,
                                  computer code (including source code or object
                                  code), products, software, services,
                                  Curriculum and all other elements of the
                                  Service (the “Materials”) provided by
                                  UStrive.com are protected by all relevant
                                  intellectual property and proprietary rights
                                  and applicable laws. All Materials contained
                                  in the Service are the property of UStrive.com
                                  or our third-party licensors. Except as
                                  expressly authorized by UStrive.com, you may
                                  not make use of the Materials. UStrive.com
                                  reserves all rights to the Materials not
                                  granted expressly in these Terms.For any
                                  feedback or suggestions provided by you to us
                                  regarding any Materials (“Feedback”), you
                                  hereby assign to us all of your right, title,
                                  and interest in and to the Feedback, including
                                  all intellectual property and proprietary
                                  rights.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>Indemnity.</strong> You agree that you
                                  will be responsible for your use of the
                                  Service, and you agree to defend, indemnify,
                                  and hold harmless UStrive.com and its
                                  officers, directors, employees, consultants,
                                  affiliates, subsidiaries and agents
                                  (collectively, the “UStrive.com Entities“)
                                  from and against any and all claims,
                                  liabilities, damages, losses, and expenses,
                                  including reasonable attorneys’ fees and
                                  costs, arising out of or in any way connected
                                  with: (a) your access to, use of, or alleged
                                  use of the Service; (b) your violation of
                                  these Terms or any representation, warranty,
                                  or agreements referenced in the Terms, or any
                                  applicable law or regulation; (c) your
                                  violation of any third-party right, including
                                  without limitation any intellectual property
                                  right, publicity, confidentiality, property or
                                  privacy right; or (d) any disputes or issues
                                  between you and any third party. We reserve
                                  the right, at our own expense, to assume the
                                  exclusive defense and control of any matter
                                  otherwise subject to indemnification by you
                                  (and without limiting your indemnification
                                  obligations with respect to such matter), and
                                  in such case, you agree to cooperate with our
                                  defense of such claim.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>Disclaimers; No Warranties</strong>
                                </p>
                                <p>
                                  THE SERVICE AND ALL MATERIALS AND CONTENT
                                  AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS
                                  IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT
                                  WARRANTY OR CONDITION OF ANY KIND, EITHER
                                  EXPRESS OR IMPLIED. THE UStrive.com ENTITIES
                                  SPECIFICALLY (BUT WITHOUT LIMITATION) DISCLAIM
                                  ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                                  IMPLIED, RELATING TO THE SERVICE AND ALL
                                  MATERIALS AND CONTENT AVAILABLE THROUGH THE
                                  SERVICE, INCLUDING BUT NOT LIMITED TO: (A) ANY
                                  IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                                  FOR A PARTICULAR PURPOSE, TITLE, QUIET
                                  ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY
                                  WARRANTIES ARISING OUT OF COURSE OF DEALING,
                                  USAGE, OR TRADE. THE UStrive.com ENTITIES DO
                                  NOT WARRANT THAT THE SERVICE OR ANY PART OF
                                  THE SERVICE, OR ANY MATERIALS OR CONTENT
                                  OFFERED THROUGH THE SERVICE, WILL BE
                                  UNINTERRUPTED, SECURE, OR FREE OF ERRORS,
                                  VIRUSES, OR OTHER HARMFUL COMPONENTS, AND DO
                                  NOT WARRANT THAT ANY OF THE FOREGOING WILL BE
                                  CORRECTED.
                                </p>
                                <p>
                                  ALL USERS, INCLUDING ALL REGISTERED MENTORS
                                  AND MENTEES, USE THE SERVICE AT YOUR SOLE
                                  DISCRETION.NO ADVICE OR INFORMATION, WHETHER
                                  ORAL OR WRITTEN, OBTAINED BY YOU FROM THE
                                  SERVICE OR ANY MATERIALS OR CONTENT AVAILABLE
                                  ON OR THROUGH THE SERVICE WILL CREATE ANY
                                  WARRANTY REGARDING ANY OF THE UStrive.com
                                  ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY
                                  STATED IN THESE TERMS. THE UStrive.com
                                  ENTITIES do not GUARANTEE COLLEGE ADMISSION IN
                                  ANY COLLEGE, NOR DO THEY GUARANTEE YOUR MENTOR
                                  WILL MEET YOUR EXPECTATIONS FOR MENTORSHIP.
                                  ANY ADVICE PROVIDED BY YOUR MENTOR IS
                                  INDEPENDENT OF UStrive.com, AND UStrive.com
                                  DOES NOT TAKE ANY REPSONSIBILITY FOR ANY
                                  ADVICE RENDERED TO YOU OR FOR ANY
                                  COMMUNICATION BETWEEN MENTORS AND MENTEES.
                                  YOUR MENTOR WILL NOT SUBMIT COLLEGE
                                  APPLICATIONS FOR YOU AND YOU ARE USING YOUR
                                  MENTOR’S ADVICE AT YOUR SOLE DISCRETION AND
                                  RISK.
                                </p>
                                <p>
                                  YOU ASSUME ALL RISK FOR ALL DAMAGES THAT MAY
                                  RESULT FROM YOUR USE OF OR ACCESS TO THE
                                  SERVICE, YOUR DEALINGS WITH OTHER SERVICE
                                  USERS, AND ANY MATERIALS OR CONTENT AVAILABLE
                                  THROUGH THE SERVICE. YOU UNDERSTAND AND AGREE
                                  THAT YOU USE THE SERVICE AND USE, ACCESS,
                                  DOWNLOAD, OR OTHERWISE OBTAIN MATERIALS OR
                                  CONTENT THROUGH THE SERVICE AND ANY ASSOCIATED
                                  SITES OR SERVICES AT YOUR OWN DISCRETION AND
                                  RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR
                                  ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
                                  COMPUTER SYSTEM USED IN CONNECTION WITH THE
                                  SERVICE) OR LOSS OF DATA THAT RESULTS FROM THE
                                  USE OF THE SERVICE OR THE DOWNLOAD OR USE OF
                                  SUCH MATERIALS OR CONTENT.
                                </p>
                                <p>
                                  SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER
                                  OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS
                                  THAT VARY FROM JURISDICTION TO JURISDICTION.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>Limitation of Liability</strong>
                                </p>
                                <p>
                                  IN NO EVENT WILL THE UStrive.com ENTITIES BE
                                  LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL,
                                  SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES
                                  (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
                                  LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER
                                  INTANGIBLE LOSSES) ARISING OUT OF OR RELATING
                                  TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY
                                  TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS
                                  OR CONTENT ON THE SERVICE, WHETHER BASED ON
                                  WARRANTY, CONTRACT, TORT (INCLUDING
                                  NEGLIGENCE), STATUTE OR ANY OTHER LEGAL
                                  THEORY, WHETHER OR NOT THE UStrive.com
                                  ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY
                                  OF SUCH DAMAGE.
                                </p>
                                <p>
                                  YOU AGREE THAT THE AGGREGATE LIABILITY OF THE
                                  UStrive.com ENTITIES TO YOU FOR ANY AND ALL
                                  CLAIMS ARISING OUT OF RELATING TO THE USE OF
                                  OR ANY INABILITY TO USE THE SERVICE (INCLUDING
                                  ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE
                                  SERVICE) OR OTHERWISE UNDER THESE TERMS,
                                  WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS
                                  LIMITED TO THE GREATER OF: (A) THE AMOUNTS YOU
                                  HAVE PAID TO UStrive.com FOR ACCESS TO AND USE
                                  OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE
                                  CLAIM; OR (B) $100.
                                </p>
                                <p>
                                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
                                  OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
                                  OR INCIDENTAL DAMAGES. ACCORDINGLY, THE ABOVE
                                  LIMITATION MAY NOT APPLY TO YOU.
                                </p>
                                <p>
                                  EACH PROVISION OF THESE TERMS THAT PROVIDES
                                  FOR A LIMITATION OF LIABILITY, DISCLAIMER OF
                                  WARRANTIES, OR EXCLUSION OF DAMAGES IS TO
                                  ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN
                                  THE PARTIES. THIS ALLOCATION IS AN ESSENTIAL
                                  ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN
                                  THE PARTIES. EACH OF THESE PROVISIONS IS
                                  SEVERABLE AND INDEPENDENT OF ALL OTHER
                                  PROVISIONS OF THESE TERMS. THE LIMITATIONS IN
                                  THIS SECTION 16 WILL APPLY EVEN IF ANY LIMITED
                                  REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>Governing Law.</strong> These Terms
                                  will be governed by the laws of the State of
                                  Texas without regard to conflict of law
                                  principles. To the extent that any lawsuit or
                                  court proceeding is permitted under the Terms,
                                  you and UStrive.com agree to submit to the
                                  personal and exclusive jurisdiction of the
                                  state courts and federal courts located within
                                  Austin, Texas, for the purpose of litigating
                                  all such disputes.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>General.</strong> These Terms,
                                  together with the UStrive.com Privacy Policy
                                  and any other agreements expressly
                                  incorporated by reference herein, constitute
                                  the entire and exclusive understanding and
                                  agreement between you and UStrive.com
                                  regarding your use of and access to the
                                  Service, and except as expressly permitted
                                  above may be amended only by a written
                                  agreement signed by authorized representatives
                                  of all parties to these Terms. You may not
                                  assign or transfer these Terms or your rights
                                  under the Terms, in whole or in part, by
                                  operation of law or otherwise, without our
                                  prior written consent. We may assign these
                                  Terms at any time without notice. The failure
                                  to require performance of any provision will
                                  not affect our right to require performance at
                                  any time thereafter, nor will a waiver of any
                                  breach or default of these Terms or any
                                  provision of these Terms constitute a waiver
                                  of any subsequent breach or default or a
                                  waiver of the provision itself. Use of section
                                  headers in these Terms is for convenience only
                                  and will not have any impact on the
                                  interpretation of particular provisions. In
                                  the event that any part of these Terms is held
                                  to be invalid or unenforceable, the
                                  unenforceable part will be given effect to the
                                  greatest extent possible and the remaining
                                  parts will remain in full force and effect.
                                  Upon termination of these Terms, any provision
                                  that by its nature or express terms should
                                  survive, will survive such termination or
                                  expiration, including, but not limited to,
                                  Sections 1, 4, 6, and 8 through 21.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>
                                    Dispute Resolution and Arbitration
                                  </strong>
                                </p>
                                <ol>
                                  <li>
                                    <p>
                                      <strong>Generally.</strong> In the
                                      interest of resolving disputes between you
                                      and UStrive.com in the most expedient and
                                      cost effective manner, you and UStrive.com
                                      agree that any and all disputes arising in
                                      connection with these Terms will be
                                      resolved by binding arbitration.
                                      Arbitration is more informal than a
                                      lawsuit in court. Arbitration uses a
                                      neutral arbitrator instead of a judge or
                                      jury, may allow for more limited discovery
                                      than in court, and can be subject to very
                                      limited review by courts. Arbitrators can
                                      award the same damages and relief that a
                                      court can award. Our agreement to
                                      arbitrate disputes includes, but is not
                                      limited to all claims arising out of or
                                      relating to any aspect of these Terms,
                                      whether based in contract, tort, statute,
                                      fraud, misrepresentation or any other
                                      legal theory, and regardless of whether
                                      the claims arise during or after the
                                      termination of these Terms. YOU UNDERSTAND
                                      AND AGREE THAT, BY ENTERING INTO THESE
                                      TERMS, YOU AND UStrive.com ARE EACH
                                      WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
                                      PARTICIPATE IN A CLASS ACTION.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>Exceptions.</strong>{' '}
                                      Notwithstanding subsection 19.1, we both
                                      agree that nothing in the Terms will be
                                      deemed to waive, preclude, or otherwise
                                      limit either of our right to: (a) bring an
                                      individual action in small claims court;
                                      (b) pursue enforcement actions through
                                      applicable federal, state, or local
                                      agencies where such actions are available;
                                      (c) seek injunctive relief in a court of
                                      law; or (d) to file suit in a court of law
                                      to address intellectual property
                                      infringement claims.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>Arbitrator.</strong> Any
                                      arbitration between you and UStrive.com
                                      will be governed by the Commercial Dispute
                                      Resolution Procedures and the
                                      Supplementary Procedures for Consumer
                                      Related Disputes (collectively, “AAA
                                      Rules“) of the American Arbitration
                                      Association (“AAA“), as modified by these
                                      Terms, and will be administered by the
                                      AAA. The AAA Rules and filing forms are
                                      available online at www.adr.com, by
                                      calling the AAA at 1-800-778-7879, or by
                                      contacting UStrive.com.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>Notice; Process.</strong> A party
                                      who intends to seek arbitration must first
                                      send a written notice of the dispute to
                                      the other, by certified mail or Federal
                                      Express (signature required), or in the
                                      event that we do not have a physical
                                      address on file for you, by electronic
                                      mail (“Notice“). UStrive.com’s address for
                                      Notice is: 360 Nueces St Austin, TX 78701.
                                      The Notice must: (a) describe the nature
                                      and basis of the claim or dispute; and (b)
                                      set forth the specific relief sought
                                      (“Demand“). We agree to use good faith
                                      efforts to resolve the claim directly, but
                                      if we do not reach an agreement to do so
                                      within 30 days after the Notice is
                                      received, you or UStrive.com may commence
                                      an arbitration proceeding. During the
                                      arbitration, the amount of any settlement
                                      offer made by you or UStrive.com will not
                                      be disclosed to the arbitrator until after
                                      the arbitrator makes a final decision and
                                      award, if any. In the event our dispute is
                                      finally resolved through arbitration in
                                      your favor, UStrive.com will pay you: (x)
                                      the amount awarded by the arbitrator, if
                                      any, (y) the last written settlement
                                      amount offered by UStrive.com in
                                      settlement of the dispute prior to the
                                      arbitrator’s award; or (z) $1,000.00,
                                      whichever is greater.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>Fees.</strong> In the event that
                                      you commence arbitration in accordance
                                      with these Terms, UStrive.com will
                                      reimburse you for your payment of the
                                      filing fee, unless your claim is for
                                      greater than $10,000, in which case the
                                      payment of any fees will be decided by the
                                      AAA Rules. Any arbitration hearings will
                                      take place at a location to be agreed upon
                                      in Austin, Texas, provided that if the
                                      claim is for $10,000 or less, you may
                                      choose whether the arbitration will be
                                      conducted: (a) solely on the basis of
                                      documents submitted to the arbitrator; (b)
                                      through a non-appearance based telephonic
                                      hearing; or (c) by an in-person hearing as
                                      established by the AAA Rules in the county
                                      (or parish) of your billing address. If
                                      the arbitrator finds that either the
                                      substance of your claim or the relief
                                      sought in the Demand is frivolous or
                                      brought for an improper purpose (as
                                      measured by the standards set forth in
                                      Federal Rule of Civil Procedure 11(b)),
                                      then the payment of all fees will be
                                      governed by the AAA Rules. In such case,
                                      you agree to reimburse UStrive.com for all
                                      monies previously disbursed by it that are
                                      otherwise your obligation to pay under the
                                      AAA Rules. Regardless of the manner in
                                      which the arbitration is conducted, the
                                      arbitrator will issue a reasoned written
                                      decision sufficient to explain the
                                      essential findings and conclusions on
                                      which the decision and award, if any, are
                                      based. The arbitrator may make rulings and
                                      resolve disputes as to the payment and
                                      reimbursement of fees or expenses at any
                                      time during the proceeding and upon
                                      request from either party made within 14
                                      days of the arbitrator’s ruling on the
                                      merits.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>No Class Actions.</strong> YOU AND
                                      UStrive.com AGREE THAT EACH MAY BRING
                                      CLAIMS AGAINST THE OTHER ONLY IN YOUR OR
                                      ITS INDIVIDUAL CAPACITY AND NOT AS A
                                      PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                                      CLASS OR REPRESENTATIVE PROCEEDING.
                                      Further, unless both you and UStrive.com
                                      agree otherwise, the arbitrator may not
                                      consolidate more than one person’s claims,
                                      and may not otherwise preside over any
                                      form of a representative or class
                                      proceeding.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>Modifications.</strong> In the
                                      event that UStrive.com makes any future
                                      change to this arbitration provision
                                      (other than a change to UStrive.com’s
                                      address for Notice), you may reject any
                                      such change by sending us written notice
                                      within 30 days of the change to
                                      UStrive.com’s address for Notice, in which
                                      case your account with UStrive.com will be
                                      immediately terminated and this
                                      arbitration provision, as in effect
                                      immediately prior to the amendments you
                                      reject will survive.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>Enforceability.</strong> If
                                      Subsection 19.6 is found to be
                                      unenforceable or if the entirety of this
                                      Section 19 is found to be unenforceable,
                                      then the entirety of this Section 19 will
                                      be null and void and, in such case, the
                                      parties agree that the exclusive
                                      jurisdiction and venue described in
                                      Section 17 will govern any action arising
                                      out of or related to these Terms.
                                    </p>
                                  </li>
                                </ol>
                              </li>
                              <li>
                                <p>
                                  <strong>
                                    Consent to Electronic Communications.
                                  </strong>{' '}
                                  By using the Service, you consent to receiving
                                  certain electronic communications from us as
                                  further described in our Privacy Policy.
                                  Please read our Privacy Policy to learn more
                                  about your choices regarding our electronic
                                  communications practices. You agree that any
                                  notices, agreements, disclosures, or other
                                  communications that we send to you
                                  electronically will satisfy any legal
                                  communication requirements, including that
                                  such communications be in writing.
                                </p>
                              </li>
                              <li>
                                <p>
                                  <strong>Contact Information.</strong> The
                                  services under the Terms are offered by
                                  UStrive.com at 360 Nueces St Austin, TX 78701.
                                  You may contact us by sending correspondence
                                  to the foregoing address or by emailing us at
                                  info@UStrive.com.
                                </p>
                              </li>
                            </li>
                          </li>
                        </li>
                      </li>
                    </ol>
                    <p>Last Updated: August 15, 2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Terms;
